export enum GLOBAL_PERMISSIONS {
    NO_ACCESS = 'No Access',
    ADMIN_ACCESS = 'Admin Access',
    ADVANCED_ACCESS = 'Advance Access'
}

export enum MOBILE_PERMISSIONS {
    NO_ACCESS = 'No Access',
    SECURITY_GUARD = 'Security Guard',
    SECURITY_SUPERVISOR = 'Security Supervisor'
}

export enum PERMISSION_LEVEL {
    ADMIN = 'Admin',
    ADVANCE = 'Advance',
    VIEW_ONLY = 'View Only'
}

export enum MODULE_PERMISSIONS {
    NOTICES = 'General.Communications.Notices',
    EMAILS = 'General.Communications.Emails',
    QUICK_CONTACTS = 'General.Quick Contacts',
    FLATS = 'Resident Management.Flats',
    HELPDESK_SETUP = 'Resident Management.Helpdesk.Helpdesk Setup',
    HELPDESK_TICKETS = 'Resident Management.Helpdesk.Helpdesk Tickets',
    INTERIOR_WORK_AND_RENOVATION = 'Resident Management.Interior Work/Renovation',
    VIOLATION_SETUP = 'Resident Management.Violation Setup',
    VIOLAION_TICKETS = 'Resident Management.Violation Tickets',
    ROLES_AND_DEPARTMENTS = 'Staff Management.Roles and Departments',
    STAFF_DIRECTORY = 'Staff Management.Staff Directory',
    HELPER = 'Staff Management.Helper',
    ATTENDANCE = 'Staff Management.Attendance',
    ASSETS = 'Work Management.Assets',
    LOCATIONS = 'Work Management.Locations',
    WORK_PACKAGE = 'Work Management.Work Package',
    WORK_SCHEDULER = 'Work Management.Work Scheduler',
    WORKLOGS = 'Work Management.WorkLogs',
    ISSUES = 'Work Management.Issues',
    GATES = 'Security Operations.Gates',
    DEVICES = 'Security Operations.Devices',
    ACCESS_LOGS = 'Security Operations.Access Logs',
    PATROLLING_SHIFTS = 'Security Operations.Patrolling Shifts',
    PARCEL_LOGS = 'Security Operations.Parcel Logs',
    SECURITY_ALERTS = 'Security Operations.Security Alerts',
}


export enum ADVANCED_PERMISSIONS {

    //STAFF_DIRECTORY
    VIEW_STAFF = 'View Staff',
    ADD_STAFF = 'Add Staff',
    EDIT_STAFF = 'Edit Staff',
    DELETE_STAFF = 'Delete Staff',

    //ROLES_AND_DEPARTMENTS
    VIEW_ROLES_DEPARTMENTS = 'View Roles & Departments',
    CREATE_ROLES_DEPARTMENTS = 'Create Roles & Departments',
    EDIT_ROLES_DEPARTMENTS = 'Edit Roles & Departments',
    DELETE_ROLES_DEPARTMENTS = 'Delete Roles & Departments',

    //DEVICES
    VIEW_DEVICES = 'View Devices',
    MANAGE_DEVICES = 'Manage Devices',
    EDIT_DEVICES = 'Edit Devices',
    DELETE_DEVICES = 'Delete Devices',

    //ASSETS
    VIEW_ASSETS = 'View Asset',
    ADD_ASSETS = 'Add an Asset',
    EDIT_ASSET_DETAILS = 'Edit Asset details',
    MANAGE_GEO_TAGS = 'Manage Geo-tags',
    DELETE_ASSETS = 'Delete Assets',

    //WORK_PACKAGE
    VIEW_WORK_PACKAGE = 'View Work Package',
    ADD_WORK_PACKAGE = 'Add Work Package',
    EDIT_WORK_PACKAGE = 'Edit Work Package',
    DUPLICATE_WORK_PACKAGE = 'Duplicate Work Package',
    DELETE_WORK_PACKAGE = 'Delete Work Package',

    //WORK_SCHEDULER
    VIEW_WORK_SCHEDULE = 'View Work Schedule',
    ADD_WORK_SCHEDULE = 'Add Work Schedule',
    EDIT_WORK_SCHEDULE = 'Edit Work Schedule',
    PAUSE_RESUME_WORK_SCHEDULE = 'Pause And Resume Work Schedule',
    DEACTIVATE_DELETE_WORK_SCHEDULE = 'Deactivate And Delete Work Schedule',

    //LOCATIONS
    VIEW_LOCATIONS = 'View Locations',
    CREATE_CLUSTER = 'Create Cluster',
    EDIT_CLUSTER = 'Edit Cluster',
    DELETE_CLUSTERS = 'Delete Clusters',

    //WORKLOGS
    VIEW_WORK = 'View Work',
    PERFORM_WORK = 'Perform Work',
    REVIEW_WORK = 'Review Work',
    COMPLETED_WORK = 'Completed Work',
    EXPIRED_WORK = 'Expired Work',

    //ISSUES
    VIEW_ISSUES = 'View Issues',
    RAISE_ISSUES = 'Raise Issues',
    EDIT_ASSIGN_ISSUES = 'Edit/Assign Issues',
    UPDATE_ISSUES = 'Update Issues',
    CLOSE_ISSUES = 'Close Issues',

    //HELPER
    VIEW_HELPERS = 'View Helpers',
    ADD_HELPERS = 'Add Helpers',
    EDIT_HELPERS = 'Edit Helpers',
    DELETE_HELPERS = 'Delete Helpers',

    //GATES
    VIEW_GATES = 'View Gates',
    ADD_GATES = 'Add Gates',
    EDIT_GATES = 'Edit Gates',
    DELETE_GATES = 'Delete Gates',

    //FLATS
    VIEW_FLATS = 'View flats',
    ACTIVATE_FLATS = 'Activate flats',
    CHANGE_OWNERSHIP_FLATS = 'Change ownership of flats',

    //PATROLLING_SHIFTS
    VIEW_SHIFTS = 'View Shifts',
    CREATE_SHIFTS = 'Create Shifts',
    EDIT_SHIFT_DETAILS = 'Edit shift details',
    DELETE_SHIFTS = 'Delete Shifts',

    //ACCOUNTS
    VIEW_ACCOUNT = 'View Account',
    ADD_ACCOUNT = 'Add Account',
    EDIT_NAME = 'Edit name',
    DEACTIVATE_ACCOUNT = 'Deactivate Account',

    //TRANSFER
    VIEW_TRANSFER = 'View Transfer',
    TRANSFER_AMOUNT = 'Transfer Amount',
    REVERSE_AMOUNT = 'Reverse Amount',

    //QUICK_CONTACTS
    VIEW_CONTACTS = 'View Contacts',
    ADD_CONTACTS = 'Add Contacts',
    EDIT_CONTACTS = 'Edit Contacts',
    DELETE_CONTACTS = 'Delete Contacts',

    //NOTICES
    VIEW_NOTICES = 'View Notices',
    CREATE_NOTICES = 'Create Notices',
    DELETE_NOTICES = 'Delete Notices',

    //EMAILS
    VIEW_EMAILS = 'View Emails',
    POST_EMAILS = 'Post Emails',
    DELETE_EMAILS = 'Delete Emails',

    //HELPDESK_SETUP
    VIEW_CATEGORIES = 'View Categories',
    CREATE_CATEGORIES = 'Create Categories',
    EDIT_CATEGORIES = 'Edit Categories',
    DELETE_CATEGORIES = 'Delete Categories',

    //HELPDESK_TICKETS
    VIEW_TICKETS = 'View Tickets',
    RAISE_TICKETS = 'Raise Tickets',
    ASSIGN_TICKETS = 'Assign Tickets',
    UPDATE_TICKETS = 'Update Tickets',
    REOPEN_TICKETS = 'Reopen Tickets',
    ADD_COMMENTS = 'Add Comments',
    ASSIGN_ESCALATION_TICKET = 'Assign Escalation of a Ticket',
    TRANSFER_TICKETS = 'Transfer Tickets',

    //HEADERS
    VIEW_HEADERS = 'View Headers',
    CREATE_HEADERS = 'Create Headers',
    EDIT_HEADER_DETAILS = 'Edit Header details',
    DELETE_HEADERS = 'Delete Headers',

    //ATTENDANCE_MANAGEMENT
    VIEW_ATTENDANCE_MANAGEMENT_SHIFTS = 'View Attendance Management Shifts',
    BULK_UPDATE = 'Bulk Update',
    CREATE_HOLIDAYS = 'Create holidays',

    // INTERIOR WORK / RENOVATION
    VIEW_INTERIOR_WORK_AND_RENOVATION = 'View Interior Work/Renovation',
    ADD_NEW_REQUEST = 'Add New Request',
    APPROVE_REQUEST = 'Approve Request',
    DECLINE_REQUEST = 'Decline Request',
    RAISE_VIOLATION = 'Raise Violation', 
    EXTEND_WORK = 'Extend Work',
    CLOSE_WORK = 'Close Work',

    // VIOLATION SETUP
    VIEW_VIOLATIONS = 'View Violations',
    ADD_VIOLATION_GROUPS = 'Add Violation Groups',
    DELETE_VIOLATION = 'Delete Violation',

    // ALL VIOLATIONS
    TAKE_ACTIONS = 'Take Actions',
}