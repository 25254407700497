import { Injectable, signal } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of } from 'rxjs';
import { ADVANCED_PERMISSIONS, GLOBAL_PERMISSIONS, MODULE_PERMISSIONS } from '../models/enum';
import { ResponseJson } from '../../shared/models/interfaces';
import { IPermission } from '../models/interfaces';

@Injectable({
  providedIn: 'root'
})

export class PermissionsService extends BaseService {

  apiUrl = environment.apiUrl + '/permissions'
  userPermissions = signal<{ permissionLevel: string, permissions: IPermission[] } | null>(null);

  constructor(protected override http: HttpClient) {
    super(http);
  }

  getUserPermissions() {
    return this.get<ResponseJson<any>>(this.apiUrl);
  }

  private _getPermissions(): Observable<{ permissionLevel: string, permissions: IPermission[] }>{
    if(this.userPermissions()){
      return of(this.userPermissions()!);
    } else {
      return this.getUserPermissions().pipe( 
        map((res) => {
          if (res.success) {
            this.userPermissions.set(res.data.manage);
          }
          return this.userPermissions()!;
        })
      );
    }
  }

  hasPermission(module: MODULE_PERMISSIONS, permissionLevel: ADVANCED_PERMISSIONS) { 
   return this._getPermissions().pipe(map((userPermission) =>{
    
    if(userPermission.permissionLevel === GLOBAL_PERMISSIONS.NO_ACCESS){
      return false;
    }

    if(userPermission.permissionLevel === GLOBAL_PERMISSIONS.ADMIN_ACCESS){
      return true;
    }

    if(userPermission.permissionLevel === GLOBAL_PERMISSIONS.ADVANCED_ACCESS){
      const [mainModule, moduleName, nestedModuleName] = module.split(".");

      for(let i=0;i< userPermission.permissions.length;i++){
        if(userPermission.permissions[i].name === mainModule){
    
          const nestedArray = userPermission.permissions[i].nestedPermissions;

          for(let j=0;j< nestedArray.length;j++){
            if(nestedArray[j].name === moduleName && nestedArray[j].checked){

              if(nestedArray[j].selectedPermissionLevel === "Admin" && nestedArray[j].checked){
                return true
              }

              if(nestedArray[j].advancedPermissions?.length){
                for(let k=0;k< nestedArray[j]?.advancedPermissions?.length!;k++){
                  if(nestedArray[j]?.advancedPermissions![k]?.name === permissionLevel && nestedArray[j]?.advancedPermissions![k]?.checked){
                    return true
                  }
                }
              }else{
                const subNestedArray = nestedArray[j]?.nestedPermissions!;
                for(let k=0;k< subNestedArray.length;k++){
                  if(nestedModuleName === subNestedArray[k].name &&  subNestedArray[k].checked){
                    for(let s=0;s< subNestedArray[k]?.advancedPermissions?.length!;s++){
                      if(subNestedArray[k]?.advancedPermissions![s]?.name === permissionLevel && nestedArray[k]?.advancedPermissions![s]?.checked){
                        return true
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return false;
      
    }))
  }
}


 // if (this.userPermissions().length) {

    // } else {

    // }
    

    //   if (accessControl.permissionLevel === PermissionLevels.NO_ACCESS) {
    //    return 
    // }

    // if (accessControl.permissionLevel === PermissionLevels.ADMIN_ACCESS) {
    //     return next()
    // }

    // // Module level permission checks

    // const mainModule = module.split('.')[0]
    // const nestedModule = module.split('.')[1]

    // if (mainModule && nestedModule) {

    //     const mainModulePermission = accessControl.permissions.find(permission => permission.name == mainModule)

    //     if (mainModulePermission) {
    //         const nestedModulePermission = mainModulePermission.nestedPermissions.find(permission => permission.name == nestedModule)

    //         if (nestedModulePermission && nestedModulePermission.checked) {

    //             const subNestedModule = module.split('.')[2]

    //             if (subNestedModule) {
    //                 const subNestedModulePermission = nestedModulePermission.nestedPermissions.find(permission => permission.name == subNestedModule)

    //                 if (subNestedModulePermission && subNestedModulePermission.checked) {

    //                     if (subNestedModulePermission.selectedPermissionLevel == PermissionAccessLevels.ADMIN) {
    //                         next()
    //                         return
    //                     } else {
    //                         const permission = subNestedModulePermission.advancedPermissions.find(permission => permission.name == requiredPermission)

    //                         if (permission && permission.checked) {
    //                             next()
    //                             return
    //                         }

    //                     }
    //                 }
    //             }
    //             else {
    //                 if (nestedModulePermission.selectedPermissionLevel == PermissionAccessLevels.ADMIN || nestedModulePermission.selectedPermissionLevel == PermissionAccessLevels.VIEW_ONLY) {
    //                     next()
    //                     return
    //                 } else {
    //                     const permission = nestedModulePermission.advancedPermissions.find(permission => permission.name == requiredPermission)

    //                     if (permission && permission.checked) {
    //                         next()
    //                         return
    //                     }
    //                 }
    //             }
    //         }
    //     }


    // }