import { Routes } from "@angular/router";
import { permissionGuard } from "../../../../core/guards/permissions.guard";
import { MODULE_PERMISSIONS, ADVANCED_PERMISSIONS } from "../../../../core/models/enum";

export const residentManagementRoutes: Routes = [
    {
        path: 'renovation-works',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.WORK_PACKAGE, permissionLevel: ADVANCED_PERMISSIONS.VIEW_WORK_PACKAGE, fallBackUrl: 'dashboard' },// need to update the permissions all
        loadComponent: () =>import('./renovation-works/renovation-works.component').then(c => c.RenovationWorksComponent)
    },
    {
        path: 'violation-setup',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.WORK_PACKAGE, permissionLevel: ADVANCED_PERMISSIONS.VIEW_WORK_PACKAGE, fallBackUrl: 'dashboard' },
        loadComponent: () =>import('./violation-setup/violation-setup.component').then(c => c.ViolationSetupComponent)
    },
    {
        path: 'violation-tickets',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.WORK_PACKAGE, permissionLevel: ADVANCED_PERMISSIONS.VIEW_WORK_PACKAGE, fallBackUrl: 'dashboard' },
        loadComponent: () =>import('./violation-tickets/violation-tickets.component').then(c => c.ViolationTicketsComponent)
    }
];