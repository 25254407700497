<div class="multi-select-wrapper" [ngStyle]="{'cursor': disabled() ? 'not-allowed': 'auto'}">

    <div class="select-input" (click)="toggleDropdown()" [ngStyle]="{'pointer-events': disabled() ? 'none': '', 'border': (formControl?.errors && (formControl?.dirty || formControl?.touched) && !isDropDownOpen()) ? '1px solid var(--errorColor)': '1px solid var(--gray300)', 'color': selectedItems().length ? 'var(--blackColor)': ''}">
        @if(selectedItems().length){
            @if (selectedItems().length > 1) {
                {{selectedItems()[0].name}}, +{{selectedItems().length-1}}

            } @else {
                {{selectedItems()[0].name}}
            }
        } @else {
            {{props().placeholder}}
        }
        <i class="ph" [ngClass]="{'ph-caret-up': isDropDownOpen(), 'ph-caret-down' : !isDropDownOpen()}"></i>
    </div>

    @if (props().supressError === undefined || props().supressError === false) {
        @if(formControl){
            <div class="error-text font-weight-500" [ngClass]="{'error-text-visible': (formControl.errors && (formControl.dirty || formControl.touched) && !isDropDownOpen())}">This field is mandatory.</div>
        }
    }

    @if (isDropDownOpen()) {

        <div class="options-wrapper">

            @if (props().searchPlaceholder) {
                <div class="search-wrapper">
                    <app-search [props]="{backgroundColor: 'var(--gray50)', border: '1px solid var(--whiteColor)', borderRadius: '8px', placeholder: props().searchPlaceholder, value: searchTerm()}" (onSearch)="onSearch($event)" />
                </div>
            }

            <ul class="option-list">

                @if (props().options.length) {

                    <li class="header">
                        <mat-checkbox (change)="selectAllOptions()" [checked]="selectAllChecked()" [indeterminate]="interminate()" /> 
                        Select All
                        <hr>
                        {{selectedItems().length}}/{{props().options.length}}
                    </li>
                }

                @for (item of selectedItems(); track $index) {
                    
                    <li class="list-item" (click)="toggleOption($event, item)">
                        <mat-checkbox [checked]="true" /> 
                        {{item.name}}
                    </li>

                }

                @if(selectedItems().length){
                    <hr>
                }

                @if (props().options.length) {

                    @for (item of props().options; track $index) {

                        @if (!selectedOptions.includes(item.value)) {
    
                            <li class="list-item" (click)="toggleOption($event, item)">
                                <mat-checkbox [checked]="selectedItems().includes(item)" /> 
                                {{item.name}}
                            </li>   
    
                        }              
                        
                    }

                } @else {
                    <li class="list-item no-item">No option found</li>
                }
            </ul>

        </div>
    }

</div>