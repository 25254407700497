import { Routes } from "@angular/router";
import { permissionGuard } from "../../../../core/guards/permissions.guard";
import { MODULE_PERMISSIONS, ADVANCED_PERMISSIONS } from "../../../../core/models/enum";

export const securityOperationsRoutes: Routes = [
    {
        path: 'gates',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.GATES, permissionLevel: ADVANCED_PERMISSIONS.VIEW_GATES, fallBackUrl: 'dashboard' },
        loadComponent: () => import('./gates/gates.component').then(c => c.GatesComponent)
    },
    {
        path: 'devices',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.DEVICES, permissionLevel: ADVANCED_PERMISSIONS.VIEW_DEVICES, fallBackUrl: 'dashboard' },
        loadComponent: () => import('./devices/devices.component').then(c => c.DevicesComponent)
    },
    {
        path: 'patrol-logs',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.DEVICES, permissionLevel: ADVANCED_PERMISSIONS.VIEW_DEVICES, fallBackUrl: 'dashboard' }, // CHANGE PERMISSIONS
        loadComponent: () => import('./patrol-logs/patrol-logs.component').then(c => c.PatrolLogsComponent)
    },
    {
        path: 'patrol-shifts',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.PATROLLING_SHIFTS, permissionLevel: ADVANCED_PERMISSIONS.VIEW_SHIFTS, fallBackUrl: 'dashboard' },
        loadComponent: () => import('./patrol-shifts/patrol-shifts.component').then(c => c.PatrolShiftsComponent)
    }
]