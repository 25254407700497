import { NgClass, NgStyle } from '@angular/common';
import { Component, ElementRef, HostListener, Injector, input, OnInit, output, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { SearchComponent } from '../search/search.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

export interface Option<T> {
  name: string,
  value: T,
}

export interface MultiSelectProps<T> {
  placeholder: string,
  options: Option<T>[],
  searchPlaceholder?: string,
  supressError?: boolean,
}

@Component({
  selector: 'app-multi-select',
  standalone: true,
  imports: [
    NgStyle,
    SearchComponent,
    MatCheckboxModule,
    NgClass
  ],
  templateUrl: './multi-select.component.html',
  styleUrl: './multi-select.component.scss',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: MultiSelectComponent,
    multi: true
  }]
})

export class MultiSelectComponent<T> implements OnInit, ControlValueAccessor {

  props = input.required<MultiSelectProps<T>>();
  onOptionSearch = output<string>();
  disabled = signal<boolean>(false);
  isDropDownOpen = signal<boolean>(false);
  formControl: NgControl | null = null;
  selectAllChecked = signal<boolean>(false);
  interminate = signal<boolean>(false);
  searchTerm = signal<string>('');
  selectedItems = signal<Option<T>[]>([]);

  onChange = (value: T[]) => { }
  onTouch = () => { }

  constructor(
    private injector: Injector,
    private ref: ElementRef,
  ) { }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.isDropDownOpen.set(false);
    }
  }

  ngOnInit(): void {
    try {
      this.formControl = this.injector.get(NgControl);
    } catch { }
  }

  get selectedOptions() {
    return Object.values(this.selectedItems().map(item => item.value))
  }

  toggleDropdown() {
    this.isDropDownOpen.set(!this.isDropDownOpen());
    this.onTouch();
  }

  selectAllOptions() {
    this.selectAllChecked.set(!this.selectAllChecked());
    if (this.selectAllChecked()) {
      this.selectedItems.set(this.props().options.map((item) => { return { name: item.name, value: item.value } }));
    } else {
      this.selectedItems.set([]);
    }
    this.onChange(this.selectedItems().map(item => item.value));
  }

  toggleOption(event: Event, option: Option<T>) {
    event.stopPropagation();
    if (this.selectedItems().includes(option)) {
      this.selectedItems.update(current => [...current.filter((item) => item.value !== option.value)]);
    } else {
      this.selectedItems.update(current => [...current, option]);
    }

    this.setSelectState();
    this.onChange(this.selectedItems().map(item => item.value));
  }

  setSelectState() {
    if (this.selectedItems().length === 0) {
      this.interminate.set(false);
    }

    if (this.selectedItems().length && this.selectedItems().length < this.props().options.length) {
      this.interminate.set(true);
    }

    if (this.selectedItems().length === this.props().options.length) {
      this.selectAllChecked.set(true);
      this.interminate.set(false);
    } else {
      this.selectAllChecked.set(false);
    }
  }

  onSearch(searchTerm: string) {
    this.searchTerm.set(searchTerm);
    this.onOptionSearch.emit(this.searchTerm());
  }

  writeValue(options: T[]): void {
    if (!options) {
      return
    } else {
      this.selectedItems.set(this.props().options.filter((item) => { return options.includes(item.value) }));
      this.setSelectState();
    }
  }

  registerOnChange(fn: (value: T[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }

}