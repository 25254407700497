import { Component, signal } from '@angular/core';
import { BUTTON_THEME, ButtonComponent, ButtonProps } from "../../../../../../../shared/components/button/button.component";
import { StepperProps, StepperComponent } from '../../../../../../../shared/components/stepper/stepper.component';
import { MultiSelectComponent, MultiSelectProps, Option } from '../../../../../../../shared/components/multi-select/multi-select.component';
import { FileUploaderComponent } from '../../../../../../../shared/components/file-uploader/file-uploader.component';
import { TextInputComponent } from '../../../../../../../shared/components/text-input/text-input.component';
import { Router } from '@angular/router';
import { ISuccessDialogProps, SuccessDialogComponent } from '../../../../../../../shared/components/success-dialog/success-dialog.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { SKELETON_TYPE, LocationsSkeletonComponent } from '../../../../../../../shared/skeletons/locations-skeleton/locations-skeleton/locations-skeleton.component';
import { LocationsClusterService } from '../../services/location-cluster.service';
import { FILE_TYPES } from '../../../../../../../shared/models/enum';
import saveAs from 'file-saver';
import { TOAST_TYPE, ToastService } from '../../../../../../../shared/components/toast/toast.service';
import { ILocation } from '../../../../../../../shared/models/interfaces';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonService } from '../../../../../../../shared/services/common.service';
import { STRING_UPPER_LOWER_NUMBER_SPACES } from '../../../../../../../shared/constants/regex.constants';
import { debounceTime } from 'rxjs';
import { ALERT_DIALOG_TYPE, AlertDialogService } from '../../../../../../../shared/components/alert-dialog/alert-dialog.service';
@Component({
  selector: 'app-create-cluster',
  standalone: true,
  imports: [ButtonComponent, StepperComponent, FileUploaderComponent, MultiSelectComponent, TextInputComponent, LocationsSkeletonComponent, ReactiveFormsModule, MatDialogModule],
  templateUrl: './create-cluster.component.html',
  styleUrl: './create-cluster.component.scss',
  providers: []
})
export class CreateClusterComponent {
  BUTTON_THEME = BUTTON_THEME;
  SKELETON_TYPE = SKELETON_TYPE;
  FILE_TYPES = FILE_TYPES;

  createScreenSkeletonVisible = signal<boolean>(false);
  isLoading = signal<boolean>(false);
  reviewCluster = signal<boolean>(false);
  locations = signal<ILocation[]>([]);
  errorLocationFile = signal<Blob | null>(null);
  isLocationFileError = signal<boolean>(false);

  tryAgainButtonProps = signal<ButtonProps>({
    buttonText: 'Try Again',
    leadingIcon: 'ph ph-arrow-counter-clockwise',
    theme: BUTTON_THEME.BORDER,
  });

  downloadErrorsButtonProps = signal<ButtonProps>({
    buttonText: `Download Errors`,
    leadingIcon: 'ph ph-download-simple',
    theme: BUTTON_THEME.TRANSPARENT,
    color: "var(--errorColor)",
    padding: "6px"
  });

  stepperProps = signal<StepperProps>({
    height: '150px',
    isVerticle: true,
    activeIndex: 0,
    labels: [
      { title: 'Upload Locations', subtitle: 'Add locations' },
      { title: 'Cluster', subtitle: 'Review & Modify any locations' },
    ]
  });

  locationDropdownProps = signal<MultiSelectProps<string>>({
    placeholder: 'Select locations..',
    options: [],
    searchPlaceholder: 'Search locations'
  })

  filterForm: FormGroup = this._fb.group({
    name: new FormControl<string | null>(null, [Validators.required, Validators.pattern(STRING_UPPER_LOWER_NUMBER_SPACES)]),
    locations: new FormControl<Option<string>[] | null>(null, [Validators.required]),
  });
  

  constructor(
    private _router: Router,
    private _dialog: MatDialog,
    private _clusterService: LocationsClusterService,
    private _toastService: ToastService,
    private _fb: FormBuilder,
    private _commonService: CommonService,
    private _alertDialogService: AlertDialogService,
  ) {}

  get f() {
    return this.filterForm.controls;
  }

  ngOnInit(){
    this.fetchLocations();
    this.filterForm.valueChanges.subscribe(() => {
      const locations = this.filterForm.get('locations')?.value;
      const selectedLocations = this.locationDropdownProps().options
        .filter(option => locations.includes(option.value))
        .map(option => ({ _id: option.value, name: option.name } as ILocation));
      this.locations.set(selectedLocations);
    });

    this.f['name'].valueChanges.pipe(debounceTime(300)).subscribe((clusterName) => {
      this._clusterService.validateClusterName({ name: clusterName }).subscribe(res => {
        if (res.success) {
          if (!res.data) {
            this.f['name'].setErrors({ valueExist: 'Cluster name already exist' })
          }
        }
      })
    })
  }

  onPressedBack() {
    this._alertDialogService.showAlertDialog({
      dialogType: ALERT_DIALOG_TYPE.INFO,
      dialogTitle: { leading: { text: 'Discard Changes' } },
      dialogMessage: "You will lose all the information changed till now",
      onPrimaryButtonPressed: () => {
        this._router.navigate(['/dashboard/work-management/locations'], {
          queryParams: { tab: 1 },
        });
      }
    })
  }
  
  onSavePressed() {
    if (this.filterForm.valid) {
      this._clusterService.addCluster(this.filterForm.value).subscribe(res => {
        if (res.success) {
          this._dialog.open<SuccessDialogComponent, ISuccessDialogProps>(SuccessDialogComponent, {
            width: '600px',
            height: '550px',
            data: { leading: this.filterForm.get('name')?.value, trailing: 'Added!' }
          });
          this.locations.set([]);
          this.filterForm.setValue({ name: null, locations: null });
          this._router.navigate(['/dashboard/work-management/locations'], {
            queryParams: { tab: 1 },
          });
          this.filterForm.reset();
        }
      });
    }
    else {
      this._commonService.markFormAsDirty(this.filterForm);
    }
  }

  downloadAllLocation() {
    this._clusterService.downloadAllLocations()
    .subscribe(res => {
      const blobType =  'application/vnd.ms-excel';
      const extension =  FILE_TYPES.XLSX;
      const blob = new Blob([res as any], { type: blobType });
      const file = new File([blob], 'locations.xlsx', { type: blobType });
      saveAs(file);
    });
  }

  handleFileUpload(files: File[]): void {
    this.isLoading.update(current => !current);

    if (files.length) {
      const formData = new FormData();
      formData.append('file', files[0]);

      this._clusterService.processFile(formData).subscribe({
        next: (data) => {
          if (data instanceof Blob) {
            this.errorLocationFile.set(data);
            this.isLocationFileError.set(true);
          } else {
            this.locations.set(data.data.locations);
            this.filterForm.patchValue({ locations: data.data.locations.map((location: ILocation) => location._id) });
            this._toastService.showToast('ph ph-check-circle', `${data.data.locations.length} Locations uploaded successfully`, TOAST_TYPE.SUCCESS);
          }
        },
        error: (error) => {
          console.error('Error:', error);
        },
        complete: () => {
          this.isLoading.update(current => !current);
        }
      });
    }
  }

  saveFile() {
    const blobType =  'application/vnd.ms-excel';
    const extension =  FILE_TYPES.XLSX;
    const blob = this.errorLocationFile();
    const file = new File([blob as Blob], 'locations-error.xlsx', { type: blobType });
    saveAs(file);
  }

  fetchLocations() {
    this._clusterService.getLocations({}).subscribe(res => {
      if (res.success) {
        this.locationDropdownProps.update(prevValue => ({
          ...prevValue,
          options: res.data.map((group: ILocation) => ({
            name: group.name,
            value: group._id
          }))
        }));
      }
    });
    
  }

  onLocationSearch(searchTerm: string) {
    this._clusterService.getLocations({ searchTerm: searchTerm }).subscribe(res => {
      if (res.success) {
        this.locationDropdownProps.update(prevValue => ({
          ...prevValue,
          options: res.data.map((group: ILocation) => ({
            name: group.name,
            value: group._id
          }))
        }));
      }
    });
  }

  onDeleteLocation(_id:ILocation['_id']) {
    this.locations.set(this.locations().filter(location => location._id !== _id));
    this.filterForm.patchValue({ locations: this.locations().map(location => location._id) });
  }

  toggleLocationError() {
    this.isLocationFileError.update(current => !current);
    this.errorLocationFile.set(null);
  }

  getStarsBasedOnLocationCount() {
    if (this.locations().length === 0) {
      return "assets/images/No.ofLocations=0.svg";
    }
    else if (this.locations().length > 0 && this.locations().length <= 10) {
      return "assets/images/No.ofLocations=1-10.svg";
    }
    else if (this.locations().length > 10 && this.locations().length <= 20) {
      return "assets/images/No.ofLocations=11-20.svg";
    }
    else if (this.locations().length > 20 && this.locations().length <= 50) {
      return "assets/images/No.ofLocations=20-50.svg";
    }
    else {
      return "assets/images/No.ofLocations=50-100.svg";
    }
  }

  handleSkipAndNextButton() {
    const currentIndex = this.stepperProps().activeIndex;
    if (currentIndex < this.stepperProps().labels.length - 1) {
      this.stepperProps.update(prev => ({
      ...prev,
      activeIndex: currentIndex + 1
      }));
    }
    else {
      this.stepperProps.update(prev => ({
        ...prev,
        activeIndex: currentIndex - 1
      }));
    }

    this.reviewCluster.set(!this.reviewCluster())
  }
}
