import { Component, Injector, OnInit, input, signal } from '@angular/core';
import { STRING_UPPER_LOWER_SPECIAL_CHARACTERS_REGEX } from '../../constants/regex.constants';
import { NgStyle } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { DynamicTooltipDirective } from '../../directives/dynamic-tooltip.directive';

export interface TextInputProps {
  placeholder: string,
  numericOnly?: boolean,
  border?: string,
  maxLength?: number,
  fontSize?: string,
  color?: string,
  backgroundColor?: string,
  capitalsOnly?: boolean,
  errorTooltip?: string,
  supressError?: boolean
}

@Component({
  selector: 'app-text-input',
  standalone: true,
  imports: [
    NgStyle,
    DynamicTooltipDirective
  ],
  templateUrl: './text-input.component.html',
  styleUrl: './text-input.component.scss',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: TextInputComponent,
    multi: true
  }]
})

/* 
  ┌─────────────────────────────────────────────────────────────────────────────────────────────────────────────────┐
  │ // Input field is categorized into 3 types                                                                      │
  │ // 'Short (50 characters)', 'Medium (500 characters)' and 'Long (2200 characters)',                             │
  │ // This input field is set to small by default. to increase the limit refer props.                              │
  │ // Pattern error message should only be passed to errorText prop.                                               │
  │ // Data exist errors should be set using setErrors method on form control                                       │
  │ // Ex: setErrors({valueExist: 'your message here'})                                                             │
  │ // when numericOnly prop is set to true it will not allow character inputs to the field.                        │
  └─────────────────────────────────────────────────────────────────────────────────────────────────────────────────┘
 */

export class TextInputComponent implements ControlValueAccessor, OnInit {

  props = input.required<TextInputProps>();
  inputString = signal<string>('');
  isFocused = signal<boolean>(false);
  formControl: NgControl | null = null;
  disabled = signal<boolean>(false);
  onChange = (inputText: string) => { };
  onTouch = () => { };

  constructor(
    private injector: Injector
  ) { }

  ngOnInit(): void {
    try {
      this.formControl = this.injector.get(NgControl);
    } catch { }
  }

  writeValue(inputText: string): void {
    this.inputString.set(inputText ?? '');
  }

  registerOnChange(fn: (inputText: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }

  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (this.props().numericOnly) {
      inputElement.value = inputElement.value.replace(STRING_UPPER_LOWER_SPECIAL_CHARACTERS_REGEX, '');
    }

    if(this.props().capitalsOnly){
      inputElement.value = inputElement.value.toUpperCase()
    }

    this.inputString.set(inputElement.value);
    this.onTouch();
    this.onChange(this.inputString());
  }

}