import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import axios, { AxiosResponse } from 'axios';
import { BaseService } from '../../core/services/base.service';

@Injectable({
  providedIn: 'root'
})

export class CommonService {

  markFormAsDirty(control: AbstractControl) {
    if (control instanceof FormArray) {
      control.controls.forEach(c => this.markFormAsDirty(c));
    } else if (control instanceof FormGroup) {
      Object.values(control.controls).forEach(c => this.markFormAsDirty(c));
    } else {
      control.markAsDirty();
    }
  }

  downloadBlob(url: string): Promise<any> {
    return axios.get<AxiosResponse<Blob>>(url, { responseType: 'blob' })
  }

}